import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import ImageUpload from "../../components/image-upload/ImageUpload";
import { Avatar } from "@mui/material";
import { IMAGE_TYPES } from "../../constants";
import useSnackbar from "../../hooks/useSnackbar";
import { StreamChat } from "stream-chat";

export default function EditChat() {
	const { chatId } = useParams<{ chatId: string }>();
	const chatClient = StreamChat.getInstance(process.env.REACT_APP_USER_TOKEN!);
	const channel = chatClient.channel("messaging", chatId);

	return <EditChatForm channel={channel} />;
}

function EditChatForm({ channel }: any) {
	const [loading, setLoading] = useState(false);
	const [image, setImage] = useState<string | null>(null);
	const [chatName, setChatName] = useState<string>("");
	const [chatDescription, setChatDescription] = useState<string>("");
	const navigate = useNavigate();
	const openSnackbar = useSnackbar();

	const getChat = async (channel: any) => {
		try {
			setLoading(true);
			await channel.watch();
			const { name, image, description } = channel.data as any;
			setChatName(name || "");
			setChatDescription(description || "");
			setImage(image || "");
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	useEffect(() => {
		getChat(channel);
	}, [channel]);

	const saveChat = async () => {
		try {
			setLoading(true);
			await channel.update({
				name: chatName,
				description: chatDescription,
				image,
			});

			openSnackbar("Chat updated successfully");
			navigate(`/club`);
		} catch (error) {
			console.log(error);
			openSnackbar("Error creating chat");
		}
		setLoading(false);
	};

	const imageUploadHandle = (imageLink: string) => {
		setImage(imageLink);
	};

	const submitHandle = () => {
		saveChat();
	};

	return (
		<Paper
			sx={{
				p: 2,
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Typography component="h2" variant="h6" color="primary" gutterBottom>
				Edit Chat
			</Typography>
			{loading && (
				<Box sx={{ width: "100%", my: 0.5 }}>
					<LinearProgress />
				</Box>
			)}
			<ImageUpload onUpload={imageUploadHandle} type={IMAGE_TYPES.CHAT_PHOTO} />
			{image && (
				<Avatar
					alt="Remy Sharp"
					src={image}
					sx={{ width: 100, height: 100, marginBottom: "16px" }}
				/>
			)}
			<Grid container spacing={2} sx={{ marginBottom: "8px" }}>
				<Grid item xs={4}>
					<TextField
						name="chat-name"
						label="Chat Name"
						fullWidth
						variant="outlined"
						value={chatName}
						onChange={(event) => setChatName(event.target.value)}
					/>
				</Grid>
				<Grid item xs={8}>
					<TextField
						name="description"
						label="Description"
						fullWidth
						multiline
						variant="outlined"
						value={chatDescription}
						onChange={(event) => setChatDescription(event.target.value)}
					/>
				</Grid>
			</Grid>
			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					paddingTop: "16px",
				}}
			>
				<Button variant="contained" color="primary" onClick={submitHandle}>
					Save chat
				</Button>
			</div>
		</Paper>
	);
}
