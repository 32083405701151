import { MouseEventHandler, useCallback, useState } from "react";
import {
	Avatar,
	useChannelActionContext,
	useChannelStateContext,
	useChatContext,
} from "stream-chat-react";
import { Modal, Box, Typography } from "@mui/material";

import { PinIcon } from "../../assets";

import { ChannelInfoIcon } from "./ChannelInfoIcon";
import { useWorkspaceController } from "../../context/WorkspaceController";

import type { StreamChatType } from "../../types";
import useSnackbar from "../../../../hooks/useSnackbar";
import { modalStyles } from "../../../../constants/modalStyles";
import AddUserToChatModal from "../../../../page/clubs/AddUserToChatModal";

export const TeamChannelHeader = () => {
	const { displayWorkspace } = useWorkspaceController();
	const { client } = useChatContext<StreamChatType>();
	const { channel, watcher_count } = useChannelStateContext<StreamChatType>();
	const { closeThread } = useChannelActionContext<StreamChatType>();
	const { togglePinnedMessageListOpen } = useWorkspaceController();
	const openSnackbar = useSnackbar();

	const [open, setOpen] = useState(false);
	const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
	const onMembersClick = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const teamHeader = `# ${
		channel?.data?.name || channel?.data?.id || "random"
	}`;

	const openChannelEditPanel = useCallback(() => {
		displayWorkspace("Admin-Channel-Edit");
	}, [displayWorkspace]);

	const onPinIconClick: MouseEventHandler = useCallback(
		(event) => {
			closeThread?.(event);
			togglePinnedMessageListOpen();
		},
		[closeThread, togglePinnedMessageListOpen]
	);

	const members = Object.values(channel.state.members).filter(
		({ user }) => user?.id !== client.userID
	);
	const getMessagingHeader = () => {
		const additionalMembers = members.length - 3;

		if (!members.length) {
			return (
				<div className="workspace-header__block">
					<Avatar image={null} size={32} />
					<p className="team-channel-header__name user">Johnny Blaze</p>
				</div>
			);
		}

		return (
			<div className="workspace-header__block">
				{members.map(({ user }, i) => {
					if (i > 2) return null;
					return (
						<div key={i} className="workspace-header__block-item">
							<Avatar
								image={user?.image}
								name={user?.name || user?.id}
								size={32}
							/>
							<p className="team-channel-header__name user">
								{user?.name || user?.id || "Johnny Blaze"}
							</p>
						</div>
					);
				})}
				{additionalMembers > 0 && (
					<p className="team-channel-header__name user">{`and ${additionalMembers} more`}</p>
				)}
			</div>
		);
	};

	const getWatcherText = (watchers?: number) => {
		if (!watchers) return "No users online";
		if (watchers === 1) return "1 user online";
		return `${watchers} users online`;
	};

	const removeUserHandle = async (userId: string) => {
		await channel.removeMembers([userId]);
		openSnackbar("User has been removed");
	};

	const blockUserHandle = async (userId: string) => {
		try {
			await channel.banUser(userId, {
				banned_by_id: "bridges-business-club",
				reason: "Banned by admin",
			});
			openSnackbar("User has been banned");
		} catch (error) {
			console.error("Failed to ban user:", error);
			openSnackbar("Failed to ban user");
		}
	};

	const unblockUserHandle = async (userId: string) => {
		try {
			await channel.unbanUser(userId);
			openSnackbar("User has been unbanned");
		} catch (error) {
			console.error("Failed to unban user:", error);
			openSnackbar("Failed to unban user");
		}
	};

	return (
		<div className="team-channel-header__container">
			{channel.type === "messaging" ? (
				getMessagingHeader()
			) : (
				<div className="workspace-header__block">
					<div className="team-channel-header__name workspace-header__title">
						{teamHeader}
					</div>
					<button onClick={openChannelEditPanel}>
						<ChannelInfoIcon />
					</button>
				</div>
			)}
			<div className="workspace-header__block">
				<div className="workspace-header__subtitle">
					{getWatcherText(watcher_count)}
				</div>
				<button className="workspace-header__subtitle" onClick={onMembersClick}>
					Manage Members
				</button>
				<button
					className="workspace-header__subtitle"
					onClick={() => setIsAddUserModalOpen(true)}
				>
					Add Members
				</button>
				<button className="workspace-header__subtitle" onClick={onPinIconClick}>
					<PinIcon />
					Pins
				</button>
			</div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className="manage-members-modal"
			>
				<Box sx={{ ...modalStyles, p: "16px" }}>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						Chat members
					</Typography>
					<div className="members-list">
						{members.map(({ user, banned }, i) => (
							<div key={i} className="member-item">
								<div className="member-info">
									<Avatar
										image={user?.image}
										name={user?.name || user?.id}
										size={32}
									/>
									<p className="team-channel-header__name user">
										{user?.name || user?.id}
									</p>
								</div>
								<div className="member-actions">
									<button onClick={() => removeUserHandle(user?.id!)}>
										Remove
									</button>
									{banned ? (
										<button onClick={() => unblockUserHandle(user?.id!)}>
											Unblock
										</button>
									) : (
										<button onClick={() => blockUserHandle(user?.id!)}>
											Block
										</button>
									)}
								</div>
							</div>
						))}
					</div>
				</Box>
			</Modal>
			<AddUserToChatModal
				isOpen={isAddUserModalOpen}
				channel={channel}
				onClose={() => setIsAddUserModalOpen(false)}
			/>
		</div>
	);
};
