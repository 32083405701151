import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContent,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	SelectChangeEvent,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import useDebounce from "../../hooks/useDebounce";
import { CompanyType, KeywordType, UserType } from "../../types";
import { API } from "../../queries/api";
import ps from "./css/modal.module.scss";
import cn from "classnames";
import DEFAULT_USER_IMG from "../../imgs/Default_user_img.png";
import useSnackbar from "../../hooks/useSnackbar";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import useSelfUser from "../../hooks/useSelfUser";
import { useFormikContext } from "formik";

interface AddUserToCompanyModalProps {
	isOpen: boolean;
	companyRoles: KeywordType[];
	onClose: () => void;
}

export default function AddUserToCompanyModal({
	isOpen,
	companyRoles,
	onClose,
}: AddUserToCompanyModalProps) {
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [searchResults, setSearchResults] = useState<UserType[]>([]);
	const [error, setError] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedUser, setSelectedUser] = useState<UserType | null>();
	const [selectedRole, setSelectedRole] = useState<KeywordType | undefined>();
	const [defaultUsers, setDefaultUsers] = useState<UserType[]>([]);
	const openSnackbar = useSnackbar();
	const { values: company, setFieldValue } = useFormikContext<CompanyType>();

	const submitHandle = async () => {
		setLoading(true);
		try {
			const newPerson = {
				user: selectedUser,
				role: selectedRole,
				admin: false,
			};
			const updatedPeople = Array.isArray(company.people)
				? [...company.people, newPerson]
				: [newPerson];
			const payload = {
				...company,
				people: updatedPeople,
			};
			const { status } = await API.post(`company`, payload);
			if (status === 200) {
				openSnackbar("User successfully added to company");
				setFieldValue("people", updatedPeople);
			} else {
				openSnackbar("Error fetching requests");
			}

			setSelectedUser(null);
			setSelectedRole(undefined);
			setSearchQuery("");
			onClose();
		} catch (error) {
			console.log(error);
			openSnackbar("Failed to add users to company");
		}
		setLoading(false);
	};

	const debouncedSearchTerm = useDebounce(searchQuery, 500);

	const searchUsers = async (query: string) => {
		try {
			setLoading(true);
			const payload = {
				page: 0,
				size: 10,
				reverse: true,
			};
			const { data, status } = await API.post(
				`users/search?searchStr=${query}&idClub=${company?.idClub}`,
				payload
			);
			const users = data?.items;
			if (status === 200 && !!users.length) {
				setSearchResults(data?.items);
			} else {
				setSearchResults([]);
			}
		} catch (error) {
			console.log(error);
			setSearchResults([]);
		}
		setLoading(false);
	};

	const getDefaultUsers = async () => {
		try {
			setLoading(true);
			const payload = {
				page: 0,
				size: 15,
				reverse: true,
			};
			const { data, status } = await API.post(
				`users?idClub=${company?.idClub}`,
				payload
			);
			if (status === 200 && !!data?.items?.length) {
				setDefaultUsers(data?.items);
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event?.target?.value);
	};

	const handleCloseModal = () => {
		onClose();
	};

	useEffect(() => {
		if (debouncedSearchTerm.trim().length === 0) {
			setError("");
			setSearchResults(defaultUsers);
		} else if (debouncedSearchTerm.trim().length < 3) {
			setError("Please enter a search query. (3+ characters)");
			setSearchResults([]);
		} else {
			searchUsers(debouncedSearchTerm);
			setError("");
		}
	}, [debouncedSearchTerm, defaultUsers]);

	useEffect(() => {
		getDefaultUsers();
	}, []);

	const handleChangeRole = (event: SelectChangeEvent) => {
		const role = companyRoles.find((role) => role.word === event.target.value);
		setSelectedRole(role);
	};

	return (
		<Dialog open={isOpen} onClose={handleCloseModal} fullWidth>
			<DialogTitle id="alert-dialog-title">Add user to company</DialogTitle>
			<DialogContent>
				{loading && (
					<Box sx={{ width: "100%", my: 0.5 }}>
						<LinearProgress />
					</Box>
				)}
				{!selectedUser && (
					<TextField
						size="small"
						label="Search for users"
						variant="outlined"
						fullWidth
						value={searchQuery}
						onChange={handleSearchChange}
						sx={{ my: 1 }}
						InputProps={{
							endAdornment: (
								<IconButton
									type="button"
									sx={{ p: "10px" }}
									aria-label="search"
								>
									<SearchIcon />
								</IconButton>
							),
						}}
					/>
				)}
				{error && <Typography color="error">{error}</Typography>}
				<div className={cn(ps["search-table"])}>
					{selectedUser ? (
						<>
							<Typography variant="h6">Selected user:</Typography>
							<div className={cn(ps["table-item"])} key={selectedUser.id}>
								<img
									className={cn(ps.avatar)}
									src={selectedUser.imageLink || DEFAULT_USER_IMG}
									alt={selectedUser.firstName}
									onError={(
										ev: React.SyntheticEvent<HTMLImageElement, Event>
									) => {
										(ev.target as HTMLImageElement).src = DEFAULT_USER_IMG;
									}}
								/>
								<div>
									{selectedUser.firstName} {selectedUser.lastName}
								</div>
								<Button
									size="small"
									onClick={() => setSelectedUser(null)}
									sx={{ marginLeft: "auto" }}
								>
									Cancel
								</Button>
							</div>
						</>
					) : (
						!!searchResults.length &&
						searchResults.map((user) => {
							return (
								<div className={cn(ps["table-item"])} key={user.id}>
									<img
										className={cn(ps.avatar)}
										src={user.imageLink || DEFAULT_USER_IMG}
										alt={user.firstName}
										onError={(
											ev: React.SyntheticEvent<HTMLImageElement, Event>
										) => {
											(ev.target as HTMLImageElement).src = DEFAULT_USER_IMG;
										}}
									/>
									<div>
										{user.firstName} {user.lastName}
									</div>
									<Button
										size="small"
										onClick={() => setSelectedUser(user)}
										sx={{ marginLeft: "auto" }}
									>
										Select
									</Button>
								</div>
							);
						})
					)}
					{selectedUser && (
						<FormControl sx={{ width: "200px", marginTop: 2 }}>
							<InputLabel id="role">Select role</InputLabel>
							<Select
								labelId="role"
								id="role"
								value={selectedRole?.word}
								label="Select role"
								onChange={handleChangeRole}
							>
								{companyRoles.map((role) => (
									<MenuItem key={role.id} value={role.word}>
										{role.word}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseModal} variant="outlined">
					Cancel
				</Button>
				<Button
					onClick={submitHandle}
					variant="contained"
					disabled={!selectedUser || !selectedRole || loading}
				>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}
