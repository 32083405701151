import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContent,
	DialogContentText,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import useDebounce from "../../hooks/useDebounce";
import { UserType } from "../../types";
import { API } from "../../queries/api";
import ps from "./css/clubs.module.scss";
import cn from "classnames";
import DEFAULT_USER_IMG from "../../imgs/Default_user_img.png";
import Grid from "@mui/material/Grid";
import useSnackbar from "../../hooks/useSnackbar";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import useSelfUser from "../../hooks/useSelfUser";

interface AddUserToChatModalProps {
	isOpen: boolean;
	channel: any;
	onClose: () => void;
}

export default function AddUserToChatModal({
	isOpen,
	channel,
	onClose,
}: AddUserToChatModalProps) {
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [searchResults, setSearchResults] = useState<UserType[]>([]);
	const [error, setError] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedUsers, setSelectedUsers] = useState<UserType[]>([]);
	const [defaultUsers, setDefaultUsers] = useState<UserType[]>([]);
	const { clubId } = useParams<{ clubId: string }>();
	const { selfUser } = useSelfUser();
	const openSnackbar = useSnackbar();

	const addUsersHandle = async () => {
		setLoading(true);
		try {
			const users = selectedUsers.map((user) => user.id);
			await channel.addMembers(users);
			openSnackbar("Users added to chat");
			setSelectedUsers([]);
			setSearchQuery("");
			onClose();
		} catch (error) {
			console.log(error);
			openSnackbar("Failed to add users to chat");
		}
		setLoading(false);
	};

	const debouncedSearchTerm = useDebounce(searchQuery, 500);

	const searchUsers = async (query: string) => {
		try {
			setLoading(true);
			const payload = {
				page: 0,
				size: 10,
				reverse: true,
			};
			const { data, status } = await API.post(
				`users/search?searchStr=${query}&idClub=${clubId}`,
				payload
			);
			const users = data?.items;
			if (status === 200 && !!users.length) {
				setSearchResults(data?.items);
			} else {
				setSearchResults([]);
			}
		} catch (error) {
			console.log(error);
			setSearchResults([]);
		}
		setLoading(false);
	};

	const getDefaultUsers = async () => {
		try {
			setLoading(true);
			const payload = {
				page: 0,
				size: 500,
				reverse: true,
			};
			const { data, status } = await API.post(
				`users?idClub=${clubId}`,
				payload
			);
			if (status === 200 && !!data?.items?.length) {
				setDefaultUsers([selfUser, ...data?.items]);
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event?.target?.value);
	};

	const handleCloseModal = () => {
		onClose();
	};

	useEffect(() => {
		if (debouncedSearchTerm.trim().length === 0) {
			setError("");
			setSearchResults(defaultUsers);
		} else if (debouncedSearchTerm.trim().length < 3) {
			setError("Please enter a search query. (3+ characters)");
			setSearchResults([]);
		} else {
			searchUsers(debouncedSearchTerm);
			setError("");
		}
	}, [debouncedSearchTerm, defaultUsers]);

	const selectUserHandle = (user: UserType) => {
		setSelectedUsers((prev) => {
			if (prev.find((u) => u.id === user.id)) {
				return prev;
			}
			return [...prev, user];
		});
	};

	const deselectUserHandle = (user: UserType) => {
		setSelectedUsers((prev) => prev.filter((u) => u.id !== user.id));
	};

	useEffect(() => {
		getDefaultUsers();
	}, []);

	return (
		<Dialog open={isOpen} onClose={handleCloseModal} fullWidth>
			<DialogTitle id="alert-dialog-title">Add users to chat</DialogTitle>
			<DialogContent>
				{loading && (
					<Box sx={{ width: "100%", my: 0.5 }}>
						<LinearProgress />
					</Box>
				)}
				<Grid container spacing={3} sx={{ py: 1 }}>
					<Grid item xs={6}>
						<div>
							<TextField
								size="small"
								label="Search for users"
								variant="outlined"
								fullWidth
								value={searchQuery}
								onChange={handleSearchChange}
								sx={{ mb: 1 }}
								InputProps={{
									endAdornment: (
										<IconButton
											type="button"
											sx={{ p: "10px" }}
											aria-label="search"
										>
											<SearchIcon />
										</IconButton>
									),
								}}
							/>
							{error && <Typography color="error">{error}</Typography>}
						</div>
						<div className={cn(ps["search-table"])}>
							{!!searchResults.length &&
								searchResults.map((user) => {
									return (
										<div className={cn(ps["table-item"])} key={user.id}>
											<img
												className={cn(ps.avatar)}
												src={user.imageLink || DEFAULT_USER_IMG}
												alt={user.firstName}
												onError={(
													ev: React.SyntheticEvent<HTMLImageElement, Event>
												) => {
													(ev.target as HTMLImageElement).src =
														DEFAULT_USER_IMG;
												}}
											/>
											<div>
												{user.firstName} {user.lastName}
											</div>
											<Button
												size="small"
												onClick={() => selectUserHandle(user)}
												sx={{ marginLeft: "auto" }}
											>
												Add
											</Button>
										</div>
									);
								})}
						</div>
					</Grid>
					<Grid item xs={6}>
						<DialogContentText variant="body1">
							Selected users:
						</DialogContentText>
						<div className={cn(ps["search-table"])}>
							{!!selectedUsers.length ? (
								selectedUsers.map((user) => {
									return (
										<div className={cn(ps["table-item"])} key={user.id}>
											<img
												className={cn(ps.avatar)}
												src={user.imageLink || DEFAULT_USER_IMG}
												alt={user.firstName}
												onError={(
													ev: React.SyntheticEvent<HTMLImageElement, Event>
												) => {
													(ev.target as HTMLImageElement).src =
														DEFAULT_USER_IMG;
												}}
											/>
											<div>
												{user.firstName} {user.lastName}
											</div>
											<Button
												size="small"
												onClick={() => deselectUserHandle(user)}
												sx={{ marginLeft: "auto" }}
											>
												Remove
											</Button>
										</div>
									);
								})
							) : (
								<DialogContentText>No users selected</DialogContentText>
							)}
						</div>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseModal} variant="outlined">
					Cancel
				</Button>
				<Button
					onClick={addUsersHandle}
					variant="contained"
					disabled={!selectedUsers.length}
				>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}
