import { RouteObject, createBrowserRouter } from "react-router-dom";
// pages
import Login from "../page/login/Login";
import Home from "../page/home/Home";
import SearchUser from "../page/user/SearchUser";
import User from "../page/user/User";
import Clubs from "../page/clubs/Clubs";
import Club from "../page/clubs/Club";
import CreateChat from "../page/clubs/CreateChat";
import ClubChat from "../page/clubs/ClubChat";
import PrivateRoute from "../components/private-route/PrivateRoute";
import Admins from "../page/admins/Admins";
import CreateClub from "../page/clubs/CreateClub";
import EditClub from "../page/clubs/EditClub";
import Keywords from "../page/keywords/Keywords";
import Requests from "../page/requests/Requests";
import RegRequests from "../page/reg-requests/RegRequests";
import CompaniesList from "../page/user/CompaniesList";
import { ADMIN_ROLE } from "../constants";
import Library from "../page/library/Library";
import EditChat from "../page/clubs/EditChat";

const routes: RouteObject[] = [
	{
		path: "/login",
		element: <Login />,
	},
	{
		path: "/",
		element: <PrivateRoute />,
		children: [
			{
				path: "/",
				element: <Home />,
				children: [
					{
						index: true,
						path: "/home",
						element: <SearchUser />,
					},
					{
						path: "/search-user",
						element: <SearchUser />,
						handle: {
							accessRoles: [ADMIN_ROLE.CLUB_ADMIN, ADMIN_ROLE.CLUB_MODERATOR],
						},
					},
					{
						path: "/user/:userId",
						element: <User />,
					},
					{
						path: "/clubs",
						element: <Clubs />,
						handle: {
							accessRoles: [ADMIN_ROLE.ADMIN],
						},
					},
					{
						path: "/club/create",
						element: <CreateClub />,
						handle: {
							accessRoles: [ADMIN_ROLE.ADMIN],
						},
					},
					{
						path: "/club",
						element: <Club />,
					},
					{
						path: "/club/:clubId",
						element: <Club />,
						handle: {
							accessRoles: [ADMIN_ROLE.ADMIN],
						},
					},
					{
						path: "/club/:clubId/edit",
						element: <EditClub />,
					},
					{
						path: "/club/:clubId/chat/create",
						element: <CreateChat />,
						handle: {
							accessRoles: [ADMIN_ROLE.CLUB_ADMIN, ADMIN_ROLE.CLUB_MODERATOR],
						},
					},
					{
						path: "/club/:clubId/chat/edit/:chatId",
						element: <EditChat />,
						handle: {
							accessRoles: [ADMIN_ROLE.CLUB_ADMIN, ADMIN_ROLE.CLUB_MODERATOR],
						},
					},
					{
						path: "/club/:clubId/chat/:chatId",
						element: <ClubChat />,
						handle: {
							accessRoles: [ADMIN_ROLE.CLUB_ADMIN, ADMIN_ROLE.CLUB_MODERATOR],
						},
					},
					{
						path: "/admins",
						element: <Admins />,
						handle: {
							accessRoles: [ADMIN_ROLE.ADMIN, ADMIN_ROLE.CLUB_ADMIN],
						},
					},
					{
						path: "/keywords",
						element: <Keywords />,
						handle: {
							accessRoles: [ADMIN_ROLE.CLUB_ADMIN, ADMIN_ROLE.CLUB_MODERATOR],
						},
					},
					{
						path: "/requests",
						element: <Requests />,
						handle: {
							accessRoles: [ADMIN_ROLE.CLUB_ADMIN, ADMIN_ROLE.CLUB_MODERATOR],
						},
					},
					{
						path: "/registration-requests",
						element: <RegRequests />,
						handle: {
							accessRoles: [ADMIN_ROLE.CLUB_ADMIN, ADMIN_ROLE.CLUB_MODERATOR],
						},
					},
					{
						path: "/companies",
						element: <CompaniesList />,
						handle: {
							accessRoles: [ADMIN_ROLE.CLUB_ADMIN, ADMIN_ROLE.CLUB_MODERATOR],
						},
					},
					{
						path: "/library",
						element: <Library />,
						handle: {
							accessRoles: [ADMIN_ROLE.CLUB_ADMIN, ADMIN_ROLE.CLUB_MODERATOR],
						},
					},
				],
			},
		],
	},
];

const router = createBrowserRouter(routes);

export default router;
